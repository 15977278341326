import React from 'react'
import { Helmet } from "react-helmet"
import styled from 'styled-components'

import bgImage from './commercial-laundry-services-bg-3.jpg'
import HeaderNav from '../../navigation/HeaderNav'
import PageHeroText from './PageHeroText'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #afdde9 url(${bgImage});
  background-position: center center;
  width: 100%;
  height: 100vh;

  @media (max-width: 767px) {
    height: auto;
  }
`

const PageHeroImageBg = () => (
  <>
    <Helmet>
      <link rel="preload" as="image" href={bgImage} />
    </Helmet>

    <StyledDiv>
      <HeaderNav />
      <PageHeroText />
    </StyledDiv>
  </>
)

export default PageHeroImageBg