import React from 'react'
import { Link } from 'gatsby'

const styledP = "text-xl md:text-2xl text-white font-medium leading-relaxed mb-12"

const PageContactSection = () => (
  <div id='contact' className="bg-ff-blue-10">
    <div className="w-11/12 md:w-full xl:max-w-screen-xl mx-auto py-24">
      <div className="flex flex-wrap text-center justify-center">
        <div className="w-full md:w-10/12 lg:w-8/12">
          <p className="text-base md:text-lg text-ff-blue-2 font-medium leading-relaxed uppercase mt-4 mb-4">
            Run Your Business. We'll Handle the Laundry.
          </p>

          <h2 className="text-3xl md:text-4xl text-white font-bold leading-tight mb-12">
            Partner with Fresh Folds Laundry and wash away your worries!
          </h2>

          <p className={styledP}>
            Tired of endless laundry? Focus on your business and let Fresh Folds Laundry handle the load! With three convenient locations in Tacoma, University Place, and Parkland, we offer comprehensive commercial laundry services using state-of-the-art Electrolux machines that get your laundry done fast. From towels and linens to uniforms, we ensure your business always looks its best, with drop-off service, free Wi-Fi, and text alerts when your laundry is ready!
          </p>

          <Link to='/request-services'>
            <button className="bg-transparent hover:bg-white text-white hover:text-ff-blue-9 text-lg md:text-xl lg:text-3xl uppercase font-bold border-4 border-white hover:border-ff-blue-10 rounded-full py-4 px-12 md:px-8">
              Request Services
            </button>
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default PageContactSection