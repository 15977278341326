import React from "react"

import HomeContainer from "../components/containers/HomeContainer"
import HeadData from "../data/HeadData"
import PageHeroImageBg from "../components/sections/hero/PageHeroImageBg"
import PageIntroSection from "../components/sections/PageIntroSection"
import PageServicesSection from "../components/sections/PageServicesSection"
import PageContactSection from "../components/sections/PageContactSection"

const CommercialLaundry = () => (
  <HomeContainer>
    <HeadData 
      title="Commercial Laundry Services in Tacoma, WA" 
      description="In Tacoma, WA, Fresh Folds Laundry delivers exceptional commercial laundry services tailored to your unique needs."
      noIndex
    />

    <PageHeroImageBg />

    <PageIntroSection />

    <PageServicesSection />

    <PageContactSection />    

  </HomeContainer>
)

export default CommercialLaundry