import React from "react"
import { Link } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import fadeIn from 'react-animations/lib/fade-in'

const fadeInAnimation = keyframes`${fadeIn}`;

const StyledDiv = styled.div`
  animation: 1s ${fadeInAnimation};

  .text-shadow {
    text-shadow: 0 3px 3px #e8f1f8;
  }
`

const PageHeroText = () => (
  <StyledDiv className='w-full xl:w-9/12 px-4 sm:pt-6 sm:pb-8'>
    <div className='w-full flex flex-col items-center'>
      <h1 className='text-5xl lg:text-8xl text-blue-900 text-center font-extrabold leading-tight text-shadow tracking-wider uppercase mt-32 xl:mt-24'>
        Commercial Laundry Services in Tacoma
      </h1>

      <h2 className='text-2xl lg:text-4xl text-blue-800 text-center font-bold leading-tight text-shadow tracking-wider mt-6'>Elevate your business with Fresh Folds Laundry, your dedicated laundry partner in Tacoma. We go beyond clean linens, towels, and uniforms, ensuring they reflect the quality and care your business stands for.</h2>

      <h2 className='text-2xl lg:text-4xl text-blue-800 text-center font-bold leading-tight text-shadow tracking-wider mt-4 mb-4'>Let us handle the laundry, so you can focus on what you do best.</h2>

      <div className='md:w-7/12 lg:w-6/12 xl:w-8/12 max-w-xl flex items-center justify-center mx-auto'>
        <div className='mt-6 mb-12'>
          <Link to='/request-services'>
            <button
              className='w-full block font-display bg-blue-900 hover:bg-blue-800 text-white text-lg md:text-xl lg:text-3xl text-center uppercase font-bold border-4 border-white hover:border-ff-blue-9 rounded-full py-2 md:py-4 px-12 md:px-8'
            >
              Request Services
            </button>
          </Link>
        </div>
      </div>
    </div>
  </StyledDiv>
)

export default PageHeroText