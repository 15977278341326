import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const cardContainer = 'flex flex-col items-center m-6'
const cardTitle = 'text-2xl text-ff-blue-1 uppercase font-bold text-white text-center mb-2'
const cardDesc = 'text-base text-ff-blue-2 text-white text-center'

const PageIntroSection = () => {
  return (
    <div className="bg-ff-blue-10">
      <div className="w-11/12 md:w-full xl:max-w-screen-xl mx-auto py-24">
        <div className="flex flex-wrap text-center justify-center">
          <div className="w-full md:w-10/12 lg:w-8/12">
            <p className="text-lg md:text-xl text-ff-blue-2 font-medium leading-relaxed uppercase mt-4 mb-4">
              Create a Welcoming Environment with Freshness You Can See
            </p>

            <h2 className="text-5xl md:text-6xl text-white font-bold leading-tight">
              Commercial Laundry Services for Tacoma Businesses
            </h2>

            <p className="text-xl md:text-2xl text-ff-blue-2 font-medium leading-relaxed mt-4 mb-4">
              Fresh Folds Laundry: Your Tacoma partner for top-quality commercial laundry services. We understand the importance of clean linens and uniforms for your business and are committed to providing the highest quality laundry services. Count on us for reliable, high-quality service that helps you maintain a spotless and professional image.
            </p>
          </div>

          <div className='w-full md:w-10/12 grid grid-cols-1 lg:grid-cols-2 gap-4 mx-auto mt-16'>
            <div className='w-full'>
              <div className={cardContainer}>
                <div className='mb-6'>
                  <StaticImage
                    src="../../images/businesses/healthcare-1.jpg"
                    width={640}
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Healthcare business"
                  />
                </div>
                <h2 className={cardTitle}>Healthcare</h2>
                <p className={cardDesc}>Protect your patients and staff with Fresh Folds Laundry. We provide uncompromisingly sanitized laundry services for medical facilities, veterinary clinics, surgical centers, and more throughout Tacoma. Our rigorous cleaning process goes beyond clean, eliminating germs and bacteria to the highest industry standards, giving you complete peace of mind.</p>
              </div>
            </div>

            <div className='w-full'>
              <div className={cardContainer}>
                <div className='mb-6'>
                  <StaticImage
                    src="../../images/businesses/restaurants.jpg"
                    width={640}
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Restaurants business"
                  />
                </div>
                <h2 className={cardTitle}>Restaurants</h2>
                <p className={cardDesc}>At Fresh Folds Laundry, we understand that a clean and inviting atmosphere is essential for your restaurant's success. Our professional laundry services ensure that your tablecloths, napkins, towels, and uniforms are impeccably clean and fresh. With our reliable and efficient service, you can focus on delivering an exceptional dining experience, knowing that your linens are in expert hands.</p>
              </div>
            </div>

            <div className='w-full'>
              <div className={cardContainer}>
                <div className='mb-6'>
                  <StaticImage
                    src="../../images/businesses/hotels-motels.jpg"
                    width={640}
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Hotels/Motels business"
                  />
                </div>
                <h2 className={cardTitle}>Hotels/Motels</h2>
                <p className={cardDesc}>Elevate your guests' experience with Fresh Folds Laundry. We specialize in providing pristine, high-quality laundry services for hotels and motels, including sheets, towels, bath mats, curtains, rugs, and all linens. Our meticulous attention to detail ensures your guests enjoy a comfortable, memorable, and refreshing stay, encouraging them to return.</p>
              </div>
            </div>

            <div className='w-full'>
              <div className={cardContainer}>
                <div className='mb-6'>
                  <StaticImage
                    src="../../images/businesses/gyms-fitness-centers-1.jpg"
                    width={640}
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Gym and Fitness Centers business"
                  />
                </div>
                <h2 className={cardTitle}>Gyms and Fitness Centers</h2>
                <p className={cardDesc}>Ensure a hygienic and inviting workout environment with Fresh Folds Laundry. We expertly handle the laundry needs of gyms and fitness centers, including towels, mats, and workout gear. Our rigorous cleaning process guarantees that your equipment is always sanitized and fresh, contributing to member satisfaction and retention.</p>
              </div>
            </div>

            <div className='w-full'>
              <div className={cardContainer}>
                <div className='mb-6'>
                  <StaticImage
                    src="../../images/businesses/spas-salons.jpg"
                    width={640}
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Spas and Salons business"
                  />
                </div>
                <h2 className={cardTitle}>Spas and Salons</h2>
                <p className={cardDesc}>Indulge your clients with the ultimate in comfort. Fresh Folds Laundry provides professional laundry services for spas and salons, ensuring your towels, robes, and linens are exquisitely soft, fluffy, and inviting. Our gentle cleaning process preserves the luxurious feel your clients expect, enhancing their relaxation and overall experience.</p>
              </div>
            </div>

            <div className='w-full'>
              <div className={cardContainer}>
                <div className='mb-6'>
                  <StaticImage
                    src="../../images/businesses/airbnb.jpg"
                    width={640}
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Airbnb business"
                  />
                </div>
                <h2 className={cardTitle}>Airbnb</h2>
                <p className={cardDesc}>Ensure 5-star reviews with Fresh Folds Laundry's convenient Airbnb laundry services! We handle everything – washing, drying, and folding linens, towels, curtains, rugs, bath mats, and more. We ensure your rental property linens are always impeccably clean, fresh, and welcoming, helping you create a memorable guest experience.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageIntroSection